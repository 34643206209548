jQuery.noConflict();
(function($) {

	// delay time range for changing the next item
	const MIN_DELAY = 250;
	const MAX_DELAY = 4000;


	let gallery_teasers = JSON.parse(gallery_teaser_data.gallery_teasers);
	const gallery_teasers_total = gallery_teasers.length;

	let current_teaser_index = 0;

	function updateGrid() {
		let rand_item = Math.floor(Math.random() * 8) + 1; // plus 1 to get a 1 and not zero based item number equal to nth-child

		// reset the index count when all items have shown up
		current_teaser_index = gallery_teasers_total <= current_teaser_index ? 0 : current_teaser_index + 1

		let teaser = gallery_teasers[current_teaser_index];

		// add new galley item, hide it and fade it in
		var newItem = $(teaser).hide();
		var oldItem = $(".gallery-teaser-grid article:nth-child(" + rand_item + ")").children().first();
		$(".gallery-teaser-grid article:nth-child(" + rand_item + ")").append(newItem);

		// then remove the old gallery item
		newItem.fadeIn(300, function() {
			oldItem.remove();
		});

		let delay = Math.random() * (MAX_DELAY - MIN_DELAY) + MIN_DELAY;
		setTimeout(updateGrid, delay); // run updateGrid() function next round after the delay

	}

	function initialGridFillUp() {
		for (let i = 0; i < 9; i++) {
			let teaser = gallery_teasers[current_teaser_index];
			$(".gallery-teaser-grid article:nth-child(" + i + ")").append(teaser);
			current_teaser_index++;
		}
	}

	// first init fill of the grid
	initialGridFillUp();

	// then start the queue to change images
	updateGrid();


	// ---------------------------------------------------
	// counter 
	//let gallery_teasers_total = false; // Setzen Sie hier Ihren Wert
	const galleries_total = gallery_teaser_data.galleries_total; // get the total number of galleries (not the count of teaser)
	let count = 0;
	let isAnimating = false;

	function isInViewport(element) {
		if ($(element).length) {
			let elementTop = $(element).offset().top;
			let elementBottom = elementTop + $(element).outerHeight();
			let viewportTop = $(window).scrollTop();
			let viewportBottom = viewportTop + $(window).height() - 150;
			return elementBottom > viewportTop && elementTop < viewportBottom;
		}
		return false;
	}

	function checkAndAnimateCounter() {
		if (isInViewport($('.gallery-count')) && !isAnimating) {
			isAnimating = true;

			let intervalId = setInterval(function() {
				if (count < galleries_total) {
					count++;
					let counterText = count;
					if (counterText < 10) {
						counterText = counterText.toString().padStart(2, '0');
					}
					$('.gallery-count').text(counterText);
				} else {
					clearInterval(intervalId);
				}
			}, 100);
		}
	}

	// start counter on load when it is in viewport
	$(window).on('load', function() {
		checkAndAnimateCounter();
	});

	// start the counter when is scrolling in the viewport
	$(window).scroll(checkAndAnimateCounter);


	if (galleries_total === 0) {
		$('.gallery-counter').hide();
	}
	// set start values depending on the max count with 1 or 2 zeros
	if (galleries_total < 10) {
		$('.gallery-count').text('0');
	} else {
		$('.gallery-count').text('00');
	}

	// fix the with of the counter
	let couter_width = $('.gallery-count').outerWidth() + 5;
	$('.gallery-count').css('width', $('.gallery-count').outerWidth());

	// Rufen Sie die Funktion auf, wenn das Fenster geladen wird
	$(window).on('load', function() {
		checkAndAnimateCounter();
	});

	// Rufen Sie die Funktion erneut auf, wenn der Benutzer scrollt
	$(window).scroll(checkAndAnimateCounter);


})(jQuery);